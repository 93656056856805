.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.topContainer {
    display: flex;
    flex-direction: row;
}

.columnFlex {
    display: flex;
    flex-direction: column;
}

.spacing {
    margin-left: 10%;
}

.box {
    display: flex;
    flex-wrap: wrap;
}
.box > * {
    flex: 1 1 250px;
}

.box_ {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5%;
}
.box_ > * {
    flex: 1 1 250px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ant-table-tbody > tr > td,
.ant-descriptions-row > td {
    color: black;
}

.siderContent {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.menuContainer {
    flex-grow: 1;
    overflow-y: auto;
}

.menuItem {
    position: relative;
}

.starIcon {
    position: absolute;
    left: 25px;
    top: 50%;
    display: none;
    align-items: center;
    transform: translate(-50%, -50%);
}
.starIconSearch {
    left: 10px;
}

.menuItem:hover .starIcon {
    display: flex;
}

.menuContainer::-webkit-scrollbar {
    width: 5px;
}

.menuContainer::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
}
.favoriteMenu {
    display: flex;
}

.custom-input {
    width: 100%;
    background: none;
    border: none;
    color: #fff;
    border-bottom: 0.05px solid #a9a9a9;
    border-radius: 0px;
}

.custom-input::placeholder {
    color: #fff;
}

.custom-input:hover,
.custom-input:focus {
    background: none;
}

.custom-input:focus::placeholder {
    visibility: hidden;
}

.ant-menu-dark .ant-menu-item-selected .starIcon .anticon-star {
    color: #fff !important;
}
